import React, { useState, useEffect,  useRef } from "react";
import classes from "./Contact.module.css";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import twitter from '../../assets/twitter.png'
import insta from '../../assets/insta.png'
import linkedin from '../../assets/linkedin.png'
import discord from '../../assets/discord.png'
import TextField from "./Text Field/TextField";
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import aboutabs from "../../assets/aboutabs.png"
import emailjs from '@emailjs/browser';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px white, inset 0 -1px 0 white',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : 'white',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto white',
    outlineOffset: 2,
  }
}));

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CheckCircleRoundedIcon style={{color: 'white', width:'20', height:'20', background:'transparent'}}/>}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const ContactUsPage = () => {
  const [fname , setfname] = useState('')
  const [lname , setlname] = useState('')
  const [phone , setphone] = useState('')
  const [email , setemail] = useState('')
  const [message , setmessage] = useState('')
  const [option , setoption] = useState('')
  const [formerrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorrr, setErrorrr] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const transformedData = queryParams.get("data");
  const form = useRef();

  const handleImageClick = ()=>{window.open('https://www.example.com', '_blank')};
  
  const SendEmailHandler = async (e) => {
    e.preventDefault();
    if (fname && lname && phone && email && message && option) {
               console.log("donnnneeee");
    
     setIsSubmit(true);

     emailjs.sendForm('service_6ai0fjd', 'template_6gv2e8t', form.current, 'bmICWVNmo0AqHRehT')
               .then((result) => {
                   console.log(result.text);
                   form.current.reset();
                  
                  // setOpen(true);

               }, (error) => {
                setErrorrr(true);
                 console.log(error.text);
                 

               });
           } else {
            setFormErrors(Validation(fname,lname,phone,email,message,option));
          }
  };
  useEffect(() => {
    console.log(formerrors);
    if(Object.keys(formerrors).length === 0 && isSubmit ) {
      console.log(fname,lname,phone,email)
    }
  },[formerrors])
  
  const Validation = () => {
    const errors = {};
    
    if (!fname) {
      errors.fname = "*First Name is required!"
    }
    if (!lname) {
      errors.lname = "*Last Name is required!"
    }
    if (!email) {
      errors.email = "*Email is required and must include @!"
    } 
    if (!phone) {
      errors.phone = "*Phone Number is requierd!"
    }
    return errors;
  };

  return (
    <div className={classes.contact_container}>
      <div className={classes.bubbleImg}>
        <div className={classes.testt}> 
        <img src={aboutabs} alt='' style={{position:'relative', width:'100%',rotate: '180deg'}}/>
      </div></div>
      <img src={aboutabs} alt='' className={classes.bubbleImg1}/> 
      <div className={classes.contact_contant}>
        <h2 className={classes.header}>
          LET’S BUILD SOMETHING <br />
          THAT MATTERS!
        </h2>
        <h4>GET IN TOUCH</h4>
        <div className={classes.form}>
          <div className={classes.info}>
            <h2>Contact Information</h2>
            <div className={classes.socialMedia}>
           <div style={{position:'relative'}}> <PhoneInTalkIcon className={classes.icon}/></div><h4>+961 81 66 68 36</h4>
            </div>
            <div className={classes.socialMedia}>
            <div style={{position:'relative'}}> <MailIcon className={classes.icon}/></div><h4>info@metariba.io</h4>
            </div>
            <div className={classes.socialMedia}>
            <div style={{position:'relative'}}> <LocationOnIcon className={classes.icon}/></div><h4>
              Kantari Street, Hoteit Bldg, Hamra, <br />
              Beirut, Lebanon
            </h4></div>
            <div className={classes.socials}>
              <img className={classes.socialsIcon} src={twitter} alt="" onClick={()=>{window.open('https://twitter.com/metariba', '_blank')}}/>
              <img className={classes.socialsIcon} src={insta} alt="" onClick={()=>{window.open('https://www.instagram.com/metariba.io/', '_blank')}}/>
              <img className={classes.socialsIcon} src={discord} alt="" onClick={()=>{window.open('https://www.facebook.com/profile.php?id=100079995940144&locale=hi_IN&paipv=0&eav=AfbnT42fBbQTGDg3mN0uZQ0xT1eqXIHlJuOnWWtUMQv1Sdo6dsbsd54ZsZSGVHJKfCI', '_blank')}}/>
              <img className={classes.socialsIcon} src={linkedin} alt="" onClick={()=>{window.open('https://www.linkedin.com/company/metariba/mycompany/', '_blank')}}/>
            </div>
          </div>
          <div className={classes.email}>
              <div className={classes.email_form}>
                <div className={classes.email_form_row}>
                  <TextField
                    id="firstname"
                    label="First Name*"
                    placeholder="First Name"
                    variant="standard"
                    rows={1}
                    onChange={(e) => setfname(e.target.value)}
                    inputProps={{ style: { color: "white" } }}  
                  />
                  <TextField
                    id="lastname"
                    label="Last Name*"
                    placeholder="Last Name"
                    variant="standard"
                    rows={1}
                    onChange={(e) => setlname(e.target.value)}
                    inputProps={{ style: { color: "white" } }}
                  />
                </div>
                <div className={classes.email_form_row}>
                  <TextField
                    id="email"
                    label="Email*"
                    placeholder="Email"
                    variant="standard"
                    rows={1}
                    onChange={(e) => setemail(e.target.value)}
                    inputProps={{ style: { color: "white" } }}
                  />
                  <TextField
                    id="phonenumber"
                    label="Phone Number*"
                    placeholder="Phone Number"
                    variant="standard"
                    rows={1}
                    onChange={(e) => setphone(e.target.value)}
                    inputProps={{ style: { color: "white" } }}
                    className={classes.color}
                  />
                </div>
              </div>
              <div className={classes.select}>
                <h4>Select Subject</h4>
                <div>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className={classes.radioGroup}
                  onChange={(e) => setoption(e.target.value)}
                >
                  <FormControlLabel value="General Inquiry" control={<BpRadio style={{color:"white"}}/>} label="General Inquiry" />
                  <FormControlLabel value="Design Services" control={<BpRadio style={{color:"white"}} />} label="Design Services" />
                  <FormControlLabel value="Development Services" control={<BpRadio style={{color:"white"}}/>} label="Development Services" />
                </RadioGroup>
                </div>
              </div>
              <div className={classes.messagetext}>
              <TextField
                    id="message"
                    label="Write a message"
                    placeholder="Write a message"
                    variant="standard"
                    fullWidth
                    multiline
                    rows={5}
                    onChange={(e) => setmessage(e.target.value)}
                    inputProps={{ style: { color: "white" } }}
                  />
              </div>
              <div className={classes.button}>

                <button>SUBMIT</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
