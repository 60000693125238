import { useState } from 'react';
import './App.css';
import AboutPage from './Front-End/About Page';
import BlogPage from './Front-End/Blog Page';
import Footer from './Front-End/Common/Footer Section/Footer';
import Navbar from './Front-End/Common/Navbar Section/Navbar';
import ScrollToTop from './Front-End/Common/ScrollToTop';
import Sidebar from './Front-End/Common/Sidebar Section/Sidebar';
import ContactUsPage from './Front-End/Contact Us Page/ContactUsPage';
import LandingPage from './Front-End/Landing Page/LandingPage';
import { Navigate, Route, Routes } from 'react-router-dom';


function App() {
  const [isOpen, setIsOpen] = useState(false);

 const toggle = () => {
   setIsOpen(!isOpen);
 };
  return (
    <div className="App">
      <Navbar toggle={toggle}/>
       <Sidebar isOpen={isOpen} toggle={toggle} />
      <div style={{transition: 'opacity 0.3s ease-in-out'}}>
        <Routes>
          <Route path='/' element={<ScrollToTop><LandingPage /></ScrollToTop>} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path='/contactus' element={<ScrollToTop><ContactUsPage /></ScrollToTop>} />
          <Route path='/blog' element={<ScrollToTop><BlogPage /></ScrollToTop>} />
          <Route path='/about' element={<ScrollToTop><AboutPage/></ScrollToTop>} />
        </Routes>
    </div>
  <Footer />
    </div>
  );
}

export default App;
