import React from "react";
import classes from "./Team.module.css";
import img1 from "../../../assets/digitaltrendcard.png";
import TeamCard from "./TeamCard";
import HassanGhamloush from '../../../assets/team/HassanGhamloush.mp4'
import HusseinAlawieh from '../../../assets/team/HusseinAlawieh.mp4'
import MohamadAlawieh from '../../../assets/team/MohamadAlawieh.mp4'
import HusseinHussaini from '../../../assets/team/HusseinHussaini.mp4'
import LeaHammoud from '../../../assets/team/LeaHammoud.mp4'
import AliHareb from '../../../assets/team/AliHareb.mp4'
import MohamadBahaa from '../../../assets/team/MohamadBahaa.mp4'
import Rima from '../../../assets/team/Rima.mp4'
import HusseinHakim from '../../../assets/team/HusseinHakim.mp4'
import Amani from '../../../assets/team/Amani.mp4'
import MohamadLteif from '../../../assets/team/Mohamad.mp4'
import Kamal from '../../../assets/team/Kamal.mp4'
import Blake from '../../../assets/team/Blake.mp4'
import Evan from '../../../assets/team/Evan.mp4'
import Roger from '../../../assets/team/Roger.mp4'
import Tima from '../../../assets/team/Tima.mp4'
import { Swiper, SwiperSlide } from "swiper/react";

import aboutabs from "../../../assets/aboutabs.png"
import "swiper/css";
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';


const array1 = [
  {name: "Hassan Ghamloush", description:"FOUNDER", image: HassanGhamloush,},
  {name: "Roger Williams", description: "Graphics Engineer", image: Roger, },
  {name: "Hussein Alawieh", description: "FULL STACK DEVELOPER ", image: HusseinAlawieh, },
  {name: "Tima Mokdad", description: "Graphic Designer", image: Tima, },
];
const array2 = [
  {name: "Ayman Awada", description: "Co-FOUNDER", image: Kamal, },
  {name: "Mohamad Lteif", description:"Human Resources", image: MohamadLteif,},
  {name: "Mohamad Alawieh", description: "FULL STACK DEVELOPER ", image: MohamadAlawieh, },
  {name: "Hussein Hussaini", description: "3D ARTIST", image: HusseinHussaini, },
];
const array3 = [
  {name: "Betsy Turner", description: "Technical Director", image: Evan, },
  {name: "Mohamad Bahaa", description:"SENIOR FULL STACK DEVELOPER", image: MohamadBahaa,},
  {name: "Hussein Hakim", description: "JUNIOR FRONT-END DEVELOPER", image: HusseinHakim, },
  {name: "Blake Anderson", description: "Video Editor", image: Blake, },
];
const array4 = [
  {name: "Ali Hareb", description:"TECH LEAD", image: AliHareb,},
  {name: "Reem Ballouk", description: "FULL STACK DEVELOPER", image: Rima, },
  {name: "Amani Choujaa", description: "UI/UX Designer", image: Amani, },
  {name: "Lea Hammoud", description: "JUNIOR GRAPHIC DESIGNER", image: LeaHammoud, },
];
const team = [
  {name: "Hassan Ghamloush", description:"FOUNDER", image: HassanGhamloush,},
  {name: "Kamal Daraiseh", description: "Co-FOUNDER", image: Kamal, },
  {name: "Betsy Turner", description: "Technical Director", image: Evan, },
  {name: "Ali Hareb", description:"TECH LEAD", image: AliHareb,},
  {name: "Roger Williams", description: "Graphics Engineer", image: Roger, },
  {name: "Mohamad Lteif", description:"Human Resources", image: MohamadLteif,},
  {name: "Mohamad Bahaa", description:"SENIOR FULL STACK DEVELOPER", image: MohamadBahaa,},
  {name: "Reem Ballouk", description: "FULL STACK DEVELOPER", image: Rima, },
  {name: "Hussein Alawieh", description: "FULL STACK DEVELOPER ", image: HusseinAlawieh, },
  {name: "Mohamad Alawieh", description: "FULL STACK DEVELOPER ", image: MohamadAlawieh, },
  {name: "Hussein Hakim", description: "JUNIOR FRONT-END DEVELOPER", image: HusseinHakim, },
  {name: "Amani Choujaa", description: "UI/UX Designer", image: Amani, },
  {name: "Hussein Hussaini", description: "3D ARTIST", image: HusseinHussaini, },
  {name: "Tima Mokdad", description: "Graphic Designer", image: Tima, },
  {name: "Blake Anderson", description: "Video Editor", image: Blake, },
  {name: "Lea Hammoud", description: "JUNIOR GRAPHIC DESIGNER", image: LeaHammoud, },
 ];

const Team = () => {

  return (
    <div className={classes.team_container}>
      <div className={classes.team_content}>
    {/* <img src={aboutabs} alt='' className={classes.bubbleImg}/> */}
        <div className={classes.title_container}>
          <h1>THE TEAM YOU WANT<br/>ON YOUR PROJECT</h1>
          {/* <p>
            DEDICATED INDUSTRY EXPERTS<br />
            WITH PROVEN TRACK RECORDS
          </p> */}
        </div>
        <div className={classes.cardsContainer}>
          <div>
              {array1.map((props) => {
                return (
                    <TeamCard
                      image={props.image}
                      name={props.name}
                      description={props.description}
                    />
                );
              })}
            </div>
            <div style={{marginTop:'20%'}}>
              {array2.map((props) => {
                return (
                    <TeamCard
                      image={props.image}
                      name={props.name}
                      description={props.description}
                    />
                );
              })}
            </div>
            <div>
              {array3.map((props) => {
                return (
                    <TeamCard
                      image={props.image}
                      name={props.name}
                      description={props.description}
                    />
                );
              })}
            </div>
            <div  style={{marginTop:'20%'}}>
              {array4.map((props) => {
                return (
                    <TeamCard
                      image={props.image}
                      name={props.name}
                      description={props.description}
                    />
                );
              })}
            </div>
        </div>
        <div className={classes.swiper}>
          <Swiper
            centeredSlides={true}
            style={{
              position:'relative',
              margin: '5% 0 0% 0',  
              zIndex: '1',
              paddingBottom:'10%',
            }}
          breakpoints={{
            0: {
              slidesPerView: 2,
              },
            651: {
              slidesPerView: 3,
            },
        }}
          >
            <div style={{position:'relative', direction:'flex'}}>
            {team.map((props) => {
              return (
                <SwiperSlide
                  style={{
                    position:'relative',
                    height: "100%",
                    backgroundColor: 'transparent',
                  }}
                >
                  <div style={{position:'relative',width:'100%',height:'100%',display:'flex'}}>
                  <TeamCard
                      image={props.image}
                      name={props.name}
                      description={props.description}
                    /></div>
                </SwiperSlide>
              );
            })}</div>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Team;
