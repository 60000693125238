import React, { useEffect, useState , useRef } from "react";
import classes from "./DesignServices.module.css";
import BrandIdentity from "../../../assets/Branding.mp4";
import CharacterDesign from "../../../assets/CharacterDesign.mp4";
import WebDesign from "../../../assets/WebDesign.mp4";
import SocialMedia from "../../../assets/SocialMedia.mp4";
import NFTIMG from "../../../assets/Metavers.mp4";

const DesignServices = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [scrollPercentage1, setScrollPercentage1] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const targetDiv = document.getElementById('target-div');
      if (targetDiv) {
        const rect = targetDiv.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const scrollDistance = rect.top < viewportHeight ? viewportHeight - rect.top : 0;
        const scrollPercentage = Math.min(scrollDistance / viewportHeight * 3, 1);
        const scrollPercentage1 = Math.min(scrollDistance / viewportHeight * 4, 1);
        setScrollPercentage(scrollPercentage);
        setScrollPercentage1(scrollPercentage1);
        //  console.log(scrollDistance / viewportHeight * 1.3)
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const backgroundColor = () => {
    const red = Math.round(scrollPercentage1 * 255);
    const green = Math.round(scrollPercentage1 * 255 + 4);
    const blue = Math.round(scrollPercentage1 * 255 + 51);
    return `rgba(${red}, ${green}, ${blue}, 1)`;
  };
  const textColor = () => {
    const red = Math.round(255 - (scrollPercentage * 237));
    const green = Math.round(255 - (scrollPercentage * 226));
    const blue = Math.round(255 - (scrollPercentage * 122));
    return `rgba(${red}, ${green}, ${blue}, 1)`;
  };
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  const videoRef4 = useRef(null);
  const videoRef5 = useRef(null);

  const handleHover = (isHovering) => {
    const video1 = videoRef1.current;
    const video2 = videoRef2.current;
    const video3 = videoRef3.current;
    const video4 = videoRef4.current;
    const video5 = videoRef5.current;
    if (isHovering === "BrandIdentity") {
      video1.play();
    }  else if (isHovering === "CharacterDesign") {
      video2.play();
    }  else if (isHovering === "WebDesign") {
      video3.play();
    }  else if (isHovering === "SocialMedia") {
      video4.play();
    }  else if (isHovering === "NFTIMG") {
      video5.play();
    }   else {
      video1.pause();
      video2.pause();
      video3.pause();
      video4.pause();
      video5.pause();
      // Reset video to the beginning
    }
  };

  useEffect(() => {
    // Detect if the screen width is less than a certain threshold (e.g., 768px) to determine mobile view
    // const handleResize = () => {
      if(window.innerWidth <= 950){
        videoRef1.current.play();
        videoRef2.current.play();
        videoRef3.current.play();
        videoRef4.current.play();
        videoRef5.current.play();
       };
    // };
    // handleResize();
    // window.addEventListener('resize', handleResize);
    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, []);

  return (
    
    <div  id="testtt" className={classes.DesignServicesContainer} style={{ background: backgroundColor(),color: textColor() }}>
      <h5 className={classes.title}><b>DESIGN SERVICES</b></h5>
      <h1 className={classes.title}><b style={{textShadow: '2px 2px 20px rgba(37, 78, 199, 0.25), 2px 2px 20px rgba(37, 78, 199, 0.25)'}}>WHAT CAN WE PROVIDE?</b></h1>
      <h5 className={classes.title}>We collaborate with brands of all sizes and across industries</h5>
      <div className={classes.bigContainer}>
      <div className={classes.contentContainer} onMouseEnter={() => handleHover("BrandIdentity")} onMouseLeave={() => handleHover("")}>
        <video ref={videoRef1} playsInline  loop muted id="target-div">
          <source src={BrandIdentity} type="video/mp4" />
        </video>
        <div className={classes.description}>
          <h2>Brand Identity & Strategy</h2>
          <p>
            Metariba Branding specializes in
            creating impactful and memorable brand experiences. Through a
            strategic and creative approach,we help you develop a cohesive
            brand identity that resonates with your audience. Our services include
            crafting visual assets, defining brand messaging, and creating a compelling brand narrative.
          </p>
        </div>
      </div>

      <div className={classes.contentContainer} onMouseEnter={() => handleHover("CharacterDesign")} onMouseLeave={() => handleHover("")}>
        <video ref={videoRef2} playsInline loop muted className={classes.characterDesignVideoCont}>
          <source src={CharacterDesign}  type="video/mp4" />
        </video>
        <div className={classes.description}>
          <h2>Character Design</h2>
          <p>
            Metariba's character design service is a testament to our commitment
            to creativity and innovation We understand that in today's digital
            world, captivating and unique characters play a crucial role
            in capturing the attention and imagination of audiences.
          </p>
        </div>
      </div>

      <div className={classes.contentContainer} onMouseEnter={() => handleHover("WebDesign")} onMouseLeave={() => handleHover("")}>
        <video ref={videoRef3} playsInline loop muted className={classes.webDesignVideoCont}>
          <source src={WebDesign} type="video/mp4" />
        </video>
        <div className={classes.description}>
          <h2>Web Design</h2>
          <p>
            We ensure your brand maintains a strong presence and adapts to
            changing market dynamics. Elevate your brand with Metariba Branding
            and make a lasting impression on your customers.
          </p>
        </div>
      </div>

      <div className={classes.contentContainer} onMouseEnter={() => handleHover("SocialMedia")} onMouseLeave={() => handleHover("")}>
        <video ref={videoRef4} playsInline loop muted>
          <source src={SocialMedia} type="video/mp4"/>
        </video>
        <div className={classes.description}>
          <h2>Social Media</h2>
          <p>
            Metariba Content Creation is your go-to service for crafting
            captivating and engaging content that resonates with your audience.
            With a team of skilled creators, we offer a wide range of mediums and
            formats, including written articles, videos, visuals, and interactive
            experiences. Our goal is to bring your ideas to life and help you
            tell your brand story effectively. By leveraging the latest tools and
            staying updated with trends, we ensure that your content stands out
            in the digital landscape.
          </p>
        </div>
      </div>
      
      </div>
      <div className={classes.nftContainer}>
      <div className={classes.contentContainer} onMouseEnter={() => handleHover("NFTIMG")} onMouseLeave={() => handleHover("")}>
        <div className={classes.nftVideoCont}> 
        <video ref={videoRef5} playsInline loop muted style={{marginTop:'-2%'}}>
          <source src={NFTIMG} type="video/mp4" />
        </video></div>
        <video autoPlay playsInline loop muted style={{marginTop:'-2%'}} className={classes.testt}>
          <source src={NFTIMG} type="video/mp4" />
        </video>
        <div className={classes.nftDescription}>
          <h2>Metaverse & NFT Studio</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            lacinia velit tortor, et vulputate tortor elementum in. Sed at
            fermentum lorem. Aenean dapibus elit a lacus commodo, sit amet
            mattis elit cursus. Integer sit amet nisl augue.
          </p>
        </div>
      </div>
      </div>
    </div>
  );
};

export default DesignServices ;
