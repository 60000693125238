import React from 'react'
import BlogIntro from './Intro Section/BlogIntro'
import Trends from './Digital Trends Section/Trends'

const BlogPage = () => {
  return (
    <div>
        <BlogIntro />
        <div style={{background: "linear-gradient(55.53deg, #121D85 12.46%, #000000 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",}}><Trends /></div>
    </div>
  )
}

export default BlogPage