import React from 'react';

function FormatText({ textArray }) {
  return (
    <div>
      {textArray.map((item, index) => {
        if (typeof item === 'string') {
          const parts = item.split('*');
          return parts.map((part, partIndex) => (
            <React.Fragment key={partIndex}>
               { part.startsWith("\n") && <br /> }
              {partIndex % 2 === 0 ? (
                <>{part.startsWith("\n") ? part.substring(2) : part}</>
              ) : (
                <strong key={index}>{part.startsWith("\n") ? part.substring(2) : part}</strong> // Apply bold formatting
              )}
            </React.Fragment>
          ));
        } 

        return <span key={index}>{item}</span>; // Render non-string items as is
      })}
    </div>
  );
}

export default FormatText;