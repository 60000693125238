import React from 'react'
import classes from "./CreativeCommunity.module.css";
import img1 from "../../../assets/AboutImage1.png"
import img2 from "../../../assets/AboutImage2.png"
import img3 from "../../../assets/AboutImage5.png"
import aboutabs from "../../../assets/aboutabs.png"

const CreativeCommunity = () => {
  return (
    <div className={classes.CreativeCommunityContainer}>
    <img src={aboutabs} alt='' className={classes.bubbleImg}/>
        <div className={classes.imageContainer}>
          <img src={img1} alt='CreativeCommunity' className={classes.image1}/>
          <img src={img2} alt='CreativeCommunity' className={classes.image2}/>
        </div>
        <div className={classes.contantContainer}>
          <div className={classes.header}>
            <h2>THE CREATIVE COMMUNITY THAT WILL GROW YOUR BRAND ONLINE!</h2>
          </div>
          <div className={classes.contant}>
            <p>
                Metariba is a vibrant community of creators committed to
                guiding clients through the complexities of web3.0 with
                unwavering clarity. Our mission is to empower the next
                generation of business owners and brand ambassadors by providing them with the knowledge
                and tools necessary to translate their groundbreaking
                innovations into meaningful value within the virtual realm.
            </p>
          </div>
        </div>
        <div className={classes.imageMobile}>
        <div className={classes.imageMobile1}>
          <img src={img2} alt='CreativeCommunity' className={classes.image2}/>
        </div>
        <div className={classes.imageMobile1}>
          <img src={img3} alt='CreativeCommunity' className={classes.image1}/>
          </div></div>
    </div>
  )
}

export default CreativeCommunity
