import React from 'react'
import classes from './News.module.css'
import BlogCard from './BlogCard'


const News = () => {
  return (
    <div className={classes.news_container}>
        <div className={classes.news_content}>
            {/* <h3>NEWS & BLOGS</h3>*/}
            <h1>RECENT STUDIES</h1> 
            <h4>We collaborate with brands of all sizes and across industries</h4>
            <div className={classes.swiper}>
            <BlogCard />
            </div>
        </div>
    </div>
  )
}

export default News