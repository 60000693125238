import React, { useEffect, useRef, useState } from "react";
import classes from "./DevServices.module.css";
import SEOOptimizationIMG from "../../../assets/SEOOptimization.png";
import MetaverseDevelopmentIMG from "../../../assets/MetaverseDevelopment.png";
import EmployeeAugmentationIMG from "../../../assets/EmployeeAugmentation.png";
import AppDevelopmentIMG from "../../../assets/AppDevelopment.png";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import gsap, { TimelineLite, Power1 } from "gsap";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import "swiper/css";

const array = [
  {
    key: 1,
    title: "SEO Optimization",
    description: "A critical aspect of digital marketing aimed at improving a website's visibility and organic traffic from search engines. It involves various strategies and  techniques to optimize a website's content, structure, and technical elements to rank higher in search engine results pages",
    image: SEOOptimizationIMG,
  },
  {
    key: 2,
    title: "Metaverse Development",
    description: "The concept of the metaverse has gained significant traction in recent years, captivating the imagination of tech enthusiasts, businesses, and the general public alike. As technology continues to advance rapidly, the metaverse is emerging as the next frontier, offering a limitless digital universe where users can interact, collaborate, and experience virtual worlds like never before.",
    image: MetaverseDevelopmentIMG,
  },
  {
    key: 3,
    title: "Employee Augmentation",
    description:
      "Employee augmentation is another service we offer in the Tech industry. Employee augmentation involves providing skilled professionals to work alongside our clients' existing team, to help fill skill gaps or increase productivity.",
    image: EmployeeAugmentationIMG,
  },
  {
    key: 4,
    title: "App Development",
    description:
      "We create custom mobile applications tailored to meet our clients' specific business needs and requirements. It involves designing, coding, testing, and deploying the application to ensure it meets the desired functionalities and user experience.",
    image: AppDevelopmentIMG,
  },
];

const DevServices = () => {
  const [imageSrc, setImageSrc] = useState(SEOOptimizationIMG);
  const [opacity, setopacity] = useState("seo");

  //for mobile swiper
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const handleSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.activeIndex);
  };

  const constantValue = activeSlideIndex + 1;
  useEffect(() => {
    array.forEach((item) => {
      if (item.key === constantValue) {
        setContent(item.description);
        setTitle(item.title)
      }
    });
  }, [constantValue, array]);

  // const [degree, setdegree] = useState(0);
  const timelineRef = React.useRef(new TimelineLite({ paused: true }));

  // Function to change the image src and update the Timeline
  const changeImage = (newSrc) => {
    timelineRef.current
      .clear()
      .to(".image", { opacity: 0, duration: 0.5, ease: Power1.easeOut });
    timelineRef.current.call(() => setImageSrc(newSrc), null, null);
    timelineRef.current.to(".image", {
      opacity: 1,
      duration: 0.5,
      ease: Power1.easeIn,
    });
  };
  return (
    <>
      <div className={classes.devservices_con}>
        <div>
          <Controller>
            <Scene duration="600%" triggerHook="onLeave" pin>
              {(progress) => {
                if (progress < 0.15) {
                  setImageSrc(SEOOptimizationIMG);
                  setopacity("seo");
                }
                if (progress > 0.15 && progress < 0.35) {
                  setImageSrc(MetaverseDevelopmentIMG);
                  setopacity("metaverse");
                }
                if (progress > 0.35 && progress < 0.7) {
                  setImageSrc(EmployeeAugmentationIMG);
                  setopacity("employee");
                }
                if (progress > 0.7) {
                  setImageSrc(AppDevelopmentIMG);
                  setopacity("app");
                }

                return (
                  <section className={classes.testt}>
                    <span className={classes.imgCont}>
                      <img
                        className="image"
                        src={imageSrc}
                        alt="My Image"
                        //  style={{transform: `rotate(${degree}deg)`}}
                      />
                    </span>
                  </section>
                );
              }}
            </Scene>
          </Controller>
        </div>
        <div>
          <Controller>
            <Scene triggerHook="onLeave" duration="600%" pin>
              <Timeline>
                <Tween from={{ y: "10%" }} to={{ y: "-65%" }}>
                  <section className={classes.panel}>
                    <span
                      className={classes.panelspan}
                      style={
                        opacity == "seo" ? { opacity: 1 } : { opacity: 0.5 }
                      }
                    >
                      <h3 className={classes.title}>SEO Optimization</h3>
                      <p className={classes.description}>
                        A critical aspect of digital marketing aimed at
                        improving a website's visibility and organic traffic
                        from search engines. It involves various strategies and
                        techniques to optimize a website's content, structure,
                        and technical elements to rank higher in search engine
                        results pages
                      </p>
                    </span>
                    <span
                      className={classes.panelspan}
                      style={
                        opacity == "metaverse"
                          ? { opacity: 1 }
                          : { opacity: 0.5 }
                      }
                    >
                      <h3 className={classes.title}>Metaverse Development</h3>
                      <p className={classes.description}>
                        The concept of the metaverse has gained significant
                        traction in recent years, captivating the imagination of
                        tech enthusiasts, businesses, and the general public
                        alike. As technology continues to advance rapidly, the
                        metaverse is emerging as the next frontier, offering a
                        limitless digital universe where users can interact,
                        collaborate, and experience virtual worlds like never
                        before.
                      </p>
                    </span>
                    <span
                      className={classes.panelspan}
                      style={
                        opacity == "employee"
                          ? { opacity: 1 }
                          : { opacity: 0.5 }
                      }
                    >
                      <h3 className={classes.title}>Employee Augmentation</h3>
                      <p className={classes.description}>
                        Employee augmentation is another service we offer in the
                        Tech industry. Employee augmentation involves providing
                        skilled professionals to work alongside our clients'
                        existing team, to help fill skill gaps or increase
                        productivity.
                      </p>
                    </span>
                    <span
                      className={classes.panelspan}
                      style={
                        opacity == "app" ? { opacity: 1 } : { opacity: 0.5 }
                      }
                    >
                      <h3 className={classes.title}>App Development</h3>
                      <p className={classes.description}>
                        We create custom mobile applications tailored to meet
                        our clients' specific business needs and requirements.
                        It involves designing, coding, testing, and deploying
                        the application to ensure it meets the desired
                        functionalities and user experience.
                      </p>
                    </span>
                  </section>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>
      </div>
      <div className={classes.mobile}>
        <div>
          <Swiper className="mySwiper" pagination={true} modules={[Pagination]} onSlideChange={handleSlideChange}>
            {array.map((data) => {
              return (
                <SwiperSlide style={{background: ' #121D85 ', height: '50vh' }}>
                  <div style={{ position: "relative",width: 'fit-content', background: '#121D85' }}>
                    <img
                      src={data.image}
                      alt=""
                      width="50%"
                      style={{ justifySelf: "center", marginLeft: "25%", background: '#121D85' }}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className={classes.mobiletext}>
          <h3 style={{position:'relative'}}>{title}</h3>
          <p className={classes.description}>{content}</p>
        </div>
      </div>
    </>
  );
};

export default DevServices;
