import React from 'react'
import classes from './Footer.module.css'
import Logo from '../../../assets/topvd.webm'
import gif from '../../../assets/test2.gif'
import {FaFacebookF, FaLinkedinIn, FaTwitter, FaInstagram} from 'react-icons/fa'
import {FaXTwitter} from 'react-icons/fa6'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className={classes.footer}>
    {/* <video autoPlay loop muted className={classes.logoo}>
      <source src={Logo} type="video/webm" />
    </video> */}
    <img src={gif} alt="My GIF Image" className={classes.logoo}/>
            <div className={classes.socials}>
            <Link target='_blank' to="https://www.facebook.com/profile.php?id=100079995940144&locale=hi_IN&paipv=0&eav=AfbnT42fBbQTGDg3mN0uZQ0xT1eqXIHlJuOnWWtUMQv1Sdo6dsbsd54ZsZSGVHJKfCI" style={{textDecoration: 'none', color:'white'}}><FaFacebookF/></Link>
            <Link target='_blank' to="https://www.linkedin.com/company/metariba" style={{textDecoration: 'none', color:'white'}}><FaLinkedinIn /></Link>
            <Link target='_blank' to="https://twitter.com/metariba" style={{textDecoration: 'none', color:'white'}}><FaXTwitter/></Link>
            <Link target='_blank' to="https://www.instagram.com/metariba.io" style={{textDecoration: 'none', color:'white'}}><FaInstagram /></Link>
            </div>
            <p>METARIBA @2023</p>
    </div>
  )
}

export default Footer