import React, { useEffect, useState } from 'react'
import classes from './ContactUs.module.css'
import VD from "../../../assets/contactsectionbg.mp4";
import { Controller, Scene } from 'react-scrollmagic';
import News from '../News Section/News';
import { Link, useNavigate } from "react-router-dom";

const ContactUs = () =>{ 
  const navigate = useNavigate()
  const [test, setest] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 750, y: 0 });

  const handleMouseMove = (e) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    if (test) {
      
   
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    }; }
  }, [test]);

  const cursorStyle = {
    left: `${cursorPosition.x}px`,
    top: `${cursorPosition.y}px`,
  };

  return(
  <div style={{overflow:"hidden",paddingTop:'5%'}}>
    <div className={classes.news_content}>
            <h5 style={{fontSize:"calc(0.5rem + 1.5vw)"}}>NEWS & BLOGS</h5>
            {/* <h1>RECENT STUDIES</h1> */}
            <div className={classes.mobilepanel}><News/><div className={classes.testtt}/></div>
            </div>
            <div className={classes.test}>
    <Controller >
      <Scene pin triggerHook="onLeave">
        <span className={classes.panel}><News/></span>
     </Scene>
        
        <div className={classes.contactus_container}>
          <div className={classes.videoCon} onClick={()=>navigate('/contactus')} onMouseEnter={()=>setest(true)} onMouseLeave={()=>setest(false)} >
        <div style={cursorStyle} className={classes.customcursor}><h1 style={{marginTop:'45%',textShadow:'0 0 3px rgba(255, 255, 255), 0 0 3px rgba(255, 255, 255)'}}>CONTACT US</h1></div>
            <video className={classes.video} playsInline autoPlay loop muted  >
              <source src={VD} type="video/mp4"/>
            </video> 
            <div className={classes.header}>
              <h1>LIKE WHAT YOU SEE?</h1>
              {/* <div style={{
                  width: '250px',
                  height: '250px',
                  border: '1px solid #fff',
                  borderRadius: '50%',
                  justifyContent:'center',
                  textAlign: 'center',
                  alignContent: 'center',
              }}><h1 style={{marginTop:'45%'}}>CONTACT US</h1>
              </div> */}
          </div>
        </div>
    </div>
    </Controller></div>
  </div>
);
}
export default ContactUs;