import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import client1 from "../../../assets/Clients/client1.png";
import client2 from "../../../assets/Clients/client2.png";
import client3 from "../../../assets/Clients/client3.png";
import client4 from "../../../assets/Clients/client4.png";
import client5 from "../../../assets/Clients/client5.png";
import client6 from "../../../assets/Clients/client6.png";
import client7 from "../../../assets/Clients/client7.png";
import client8 from "../../../assets/Clients/client8.png";
import client9 from "../../../assets/Clients/client9.png";
import client10 from "../../../assets/Clients/client10.png";
import client11 from "../../../assets/Clients/client11.png";
import client12 from "../../../assets/Clients/client12.png";
import client13 from "../../../assets/Clients/client13.png";

const images = [
  {
    image: client1,
  },
  {
    image: client2,
  },
  {
    image: client3,
  },
  {
    image: client4,
  },
  {
    image: client5,
  },
  {
    image: client6,
  },
  {
    image: client7,
  },
  {
    image: client8,
    link: 'https://binsevanadvocates.com/'
  },
  {
    image: client9,
  },
  {
    image: client10,
  },
  {
    image: client11,
  },
  {
    image: client12,
  },
  {
    image:client13
  },
];

const Movearound = () => (
      <>
      <Splide
      options={{
        type: "loop",
        gap: "30px",
        drag: false,
        arrows: false,
        pagination: false,
        width: '100%',
        perPage: 9,
        autoScroll: {
          pauseOnHover: true,
          pauseOnFocus: true,
          rewind: true,
          speed: 0.8
        },
        autoWidth:true,
        omitEnd  : true,
      }}
      extensions={{ AutoScroll }}
    >
        {images.map((props) => {
          return (
              <SplideSlide>
                <a href={props.link} target="_blank">
                <img src={props.image} alt="Image 1" width='100%'/>
                </a>
              </SplideSlide>
          );
        })}
        </Splide>
      </>
);

export default Movearound;
//https://codesandbox.io/s/react-splide-auto-scroll-et6qm5?file=/src/App.js:192-198