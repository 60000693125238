import React from "react";
import classes from "./BlogIntro.module.css";
import IMG from "../../../assets/blogimg.png";
import vector from "../../../assets/blogpageimg.png";
import AuthContext from "../../../Admin Panel/auth-context";
import FormatText from "../../Common/FormatText";

const BlogIntro = () => {
  const authCtx = React.useContext(AuthContext);

  return (
    <div className={classes.blogintro_container}>
        <div className={classes.small_img}>
            <img className={classes.bubbel} src={vector} alt=""/>
        </div>
      <div className={classes.header}>
        <h1>
        {authCtx.selectedTrend.title}
        </h1>
        <p style={{width:'80%',marginLeft:'10%'}}>
          {authCtx.selectedTrend.subtitle}
        </p>
        <img src={authCtx.selectedTrend.image} alt="" className={classes.image}/>
      </div>
      <div className={classes.body}>
        <h2>{authCtx.selectedTrend.subtitle1}</h2>
        <p><FormatText textArray={authCtx.selectedTrend.description1}/></p>
        <h2>{authCtx.selectedTrend.subtitle2}</h2>
        <p><FormatText textArray={authCtx.selectedTrend.description2}/></p>
        <h2>{authCtx.selectedTrend.subtitle3}</h2>
        <p><FormatText textArray={authCtx.selectedTrend.description3}/></p>
        {authCtx.selectedTrend.subtitle4 && <h2>{authCtx.selectedTrend.subtitle4}</h2>}
        {authCtx.selectedTrend.description4 && <p><FormatText textArray={authCtx.selectedTrend.description4}/></p>}
        {authCtx.selectedTrend.subtitle5 && <h2>{authCtx.selectedTrend.subtitle5}</h2>}
        {authCtx.selectedTrend.description5 &&<p><FormatText textArray={authCtx.selectedTrend.description5}/></p>}
      </div>
    </div>
  );
};

export default BlogIntro;
