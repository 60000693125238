import classes from './Sidebar.module.css'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../../assets/test2.gif'
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Footer from '../Footer Section/Footer';
import sidebarabs1 from '../../../assets/sidebarabs1.png'
import sidebarabs2 from '../../../assets/sidebarabs2.png'


export default function Sidebar({toggle, isOpen}) {
  const [state, setState] = React.useState({
    right: false,
  });

  const list = (anchor) => (
    <>
    <img src={sidebarabs1} alt='' style={{
      position:'absolute',
      top: '100px',
      left: 0,
    }} />
    <img src={sidebarabs2} alt='' style={{
      position:'absolute',
      bottom: '200px',
      right: 0,
    }} />
    <Box
      sx={{ width:  '100%' }}
      role="presentation"
      onClick={toggle}
      onKeyDown={toggle}
      className={classes.container}
    >
      <List>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText style={{
                textAlign: 'center'
              }}>
              <Link  to='/' onClick={toggle}>
                 <img src={Logo} alt='Logo' className={classes.logo}/> 
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          </List>
          <List>
              <Link  to='servicesmob' style={{textDecoration: 'none', color:'white'}} onClick={toggle}>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
              SERVICES
              </ListItemText>
            </ListItemButton>
          </ListItem>
                     </Link>
          <Divider color='white' width='70%' style={{justifySelf: 'center', alignSelf:'center', marginLeft:'15%'}}/>
              <Link  to='/blog' style={{textDecoration: 'none', color:'white'}} onClick={toggle}>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
              NEWS
              </ListItemText>
            </ListItemButton>
          </ListItem>
                     </Link>
          <Divider color='white' width='70%' style={{justifySelf: 'center', alignSelf:'center', marginLeft:'15%'}}/>
              <Link  to='/about' style={{textDecoration: 'none', color:'white'}} onClick={toggle}>
            <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
              ABOUT
              </ListItemText>
            </ListItemButton>
          </ListItem>
                     </Link>
          <Divider color='white' width='70%' style={{justifySelf: 'center', alignSelf:'center', marginLeft:'15%'}}/>
              <Link  to='/contactus' style={{textDecoration: 'none', color:'white'}} onClick={toggle}>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
              REQUEST A QUOTE
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Box>
      </>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={isOpen}
            onClose={toggle}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}