import React from 'react'
import Home from './Home Section/Home'
import Navbar from '../Common/Navbar Section/Navbar'
import Footer from '../Common/Footer Section/Footer'
import DevServices from './Development Services Section/DevServices'
import Clients from './Our Clients Section/Clients'
import News from './News Section/News'
import ContactUs from './Contact Us Section/ContactUs'
import DesignServices from './Design Services Section/DesignServices'

const LandingPage = () => {
  return (
    <div>
        {/* <Navbar/> */}
        <Home/>
        <DesignServices/>
        <DevServices/>
        <Clients/>
        <ContactUs />
        {/* <Footer/>  */}
    </div>
  )
}

export default LandingPage