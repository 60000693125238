import React from "react";
import Classes from './TrendsBlogCard.module.css'

const TrendsBlogCard = (props) => {
  return (
    <div className={Classes.card_container}>
        <div className={Classes.card_img}>
          <img src={props.image} alt='' width='100%' height='100%'/> 
        </div>
        <div className={Classes.card_content}>
            <h3>{props.title}</h3>
            <p style={{fontWeight:'100'}}>{props.description}</p>
        {/* <button
          style={{
            background: "transparent",
            border: "1px solid #fff",
            color: "#fff",
            padding: " 0.5rem",
            boxShadow: "0px 0px 5px 0px #FFF, 0px 0px 5px 0px #FFF",
            marginBottom: "3%",
          }}
        >
          READ MORE
        </button> */}
      </div>
    </div>
  );
};

export default TrendsBlogCard;
