import React from "react";
import Classes  from './Team.module.css';
const TrendsBlogCard = (props) => {
  return (
    <div
      style={{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        width: "90%",
        marginLeft:'5%',
        height: "fit-content",
        fontFamily: "Metropolis",
        fontSize: "calc(0.5rem + 0.3vw)",
        letterSpacing: "0.01rem",
        marginBottom:'10%',
        paddingBottom:'0px',
        overflow: 'hidden'

      }}
    >
      <div className={Classes.card}>
      <video autoPlay loop playsInline  muted width='100%' >
        <source src={props.image}type="video/mp4" />
      </video>
      <div className={Classes.textContainer}>
        <h4>{props.name}</h4>
        <p style={{marginTop:'-5%',textTransform:'uppercase'}}>{props.description}</p>
      </div>
      <div className={Classes.textContainerMob}>
        <h4>{props.name}</h4>
        <p style={{textTransform:'uppercase'}}>{props.description}</p>
      </div>
      </div>
    </div>
  );
};

export default TrendsBlogCard;
