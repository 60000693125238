import React, { useState } from 'react';
import classes from './BlogCard.module.css';
import img1 from "../../../assets/NFTMarketing.png";
import img2 from "../../../assets/Web3.png";
import img3 from "../../../assets/NFTStudios.png";
import img4 from "../../../assets/3DCharacterDesign.png";
import { useNavigate } from 'react-router-dom';
import AuthContext from "../../../Admin Panel/auth-context";


const array = [
  {
    title: "NFT Marketing in 3D Works",
    subtitle:"NFTs, or Non-Fungible Tokens, have radically transformed the way we perceive and trade digital assets. From digital art to virtual real estate, the potential of NFTs seems limitless. One segment of the NFT market that's particularly eye-catching is the realm of 3D works. With advancements in 3D technology and the rise of virtual realities, 3D NFTs are ushering in a new era for artists, designers, and marketers. Let's delve into the world of NFT marketing in 3D works and understand its significance.",
    description: "NFTs, or Non-Fungible Tokens, have radically transformed the way we perceive and trade digital assets. From digital art to virtual real estate, the potential of NFTs seems limitless.",
    subtitle1: "The Allure of 3D NFTs",
    description1: ["3D NFTs offer a dynamic and immersive experience that flat, 2D artworks can't match. Whether it's a rotating sculpture, an interactive environment, or even an entire virtual city, 3D NFTs transport the viewer into a whole new dimension.","\n","\n The tangible feel of 3D works, coupled with the immutable proof of ownership via NFTs, is driving both creators and collectors towards this medium. Imagine owning a digital sculpture that you can view from all angles, place in a virtual space, or even interact with—this is the promise of 3D NFTs. "],
    subtitle2: "Marketing Strategies for 3D NFTs",
    description2: ["Virtual Showcases: Platforms like Decentraland and Cryptovoxels allow artists to display their 3D NFTs in virtual art galleries. This not only showcases the depth and detail of the artwork but also offers a unique experience to potential buyers. ","\n","\n AR & VR Integration: By merging Augmented Reality (AR) and Virtual Reality (VR) with NFTs, marketers can allow collectors to experience 3D works in the real world or immerse them in a fully virtual environment. For instance, someone could place their 3D NFT sculpture in their living room using AR glasses. ","\n","\n Collaborations and Limited Editions: Collaboration between popular digital artists can create buzz and demand. Limited editions, where a particular 3D artwork is only minted a certain number of times, can also add exclusivity and value. ","\n While 3D NFTs are innovative, they come with challenges. The file sizes are often larger, making them harder to store and trade. Also, viewing them might require specific software or hardware, limiting their accessibility to a broader audience. ","\n","\n Furthermore, the 3D NFT space is still in its infancy, leading to potential issues like copyright concerns, intellectual property rights, and even technological limitations in creating or displaying these tokens."],
    subtitle3: "The Future of 3D NFT Marketing",
    description3: ["The fusion of 3D works and NFTs is just the beginning. With the proliferation of virtual realities and metaverses, we can anticipate 3D NFTs evolving into fully interactive digital experiences. There's a potential for these NFTs to become integral parts of virtual worlds, used in games, simulations, or virtual communities. ","\n","\n In the realm of marketing, as more brands realize the value of virtual real estate and 3D assets, we might see brand-themed virtual cities, showrooms, or even exclusive branded digital art pieces. ","\n","\n NFT marketing in 3D works represents an intersection of art, technology, and commerce. As technology continues to advance and as artists push the boundaries of what's possible, the world of 3D NFTs will likely expand, offering more opportunities for creators and more immersive experiences for collectors. Like any nascent industry, there will be challenges, but the potential for growth and innovation is vast."],
    image: img1,
  },
  {
    title: "Web 3.0 Unlocking the future",
    subtitle:"Web 3.0 marks a groundbreaking leap in the evolution of the internet, promising a decentralized future that transforms the way we engage with digital technology. This paradigm shift is driven by blockchain technology, introducing new levels of transparency, security, and user autonomy.",
    description: "Web 3.0 marks a groundbreaking leap in the evolution of the internet, promising a decentralized future that transforms the way we engage with digital technology.",
    subtitle1: "Understanding Web 3.0",
    description1: ["Web 3.0, also known as the decentralized web, diverges from its predecessors, Web 1.0 and Web 2.0, with its focus on decentralization and peer-to-peer interactions. At its core lies blockchain technology, enabling the creation of decentralized networks where data and applications are distributed across a network of nodes, eliminating the need for a central authority."],
    subtitle2: "The Potential of Web 3.0",
    description2: ["* Decentralization: * Web 3.0 shifts away from the centralized control of data and power, providing increased security and resilience against single points of failure. ","\n *Transparency and Trust:* Through blockchain's immutability, Web 3.0 ensures transparent and auditable records, fostering trust between users and reducing the risk of data manipulation. ","\n *User Control:* Users regain control of their data and digital identities, deciding how and where their information is utilized, promoting privacy and data ownership. ","\n *Tokenization and Digital Assets:*  Web 3.0 introduces the concept of digital assets and tokenization, enabling new revenue models, efficient transactions, and innovative business models. ","\n *Smart Contracts:* Web 3.0 incorporates smart contracts, self-executing agreements that automate processes and eliminate the need for intermediaries, streamlining transactions and reducing costs."],
    subtitle3: "The Future of Web 3.0",
    description3: ["The potential applications of Web 3.0 are vast and varied, impacting diverse industries such as finance, healthcare, supply chain management, gaming, and more. Decentralized applications (dApps) will revolutionize traditional processes, providing enhanced security, cost-effectiveness, and global accessibility. ","\n","\n Web 3.0 stands as a paradigm shift, promising a decentralized and user-centric digital landscape. With blockchain technology as its backbone, Web 3.0 introduces unparalleled transparency, trust, and security to the internet. As we venture further into this revolutionary era, businesses, individuals, and industries worldwide are poised to embrace a new era of digital autonomy and redefine the way we interact with technology and each other."],
    image: img2,
  },
  {
    title: "Revolutionizing Art with NFT Studios",
    subtitle:"In the ever-evolving landscape of digital art and innovation, a new frontier has emerged that is captivating creators, collectors, and enthusiasts alike – Non-Fungible Tokens (NFTs) and the studios dedicated to crafting these unique digital treasures. Among these groundbreaking ventures, NFT studios focusing on 3D designs have risen to prominence, redefining the way we perceive and interact with art. In this article, we delve into the world of NFT studios for 3D designs and explore the transformative power they wield in the art industry.",
    description: "In the ever-evolving landscape of digital art and innovation, a new frontier has emerged that is captivating creators, collectors, and enthusiasts alike",
    subtitle1: "The Fusion of Technology and Creativity",
    description1: ["NFTs are digital certificates of ownership for unique items or pieces of content, secured through blockchain technology. While initially associated with 2D art and collectibles, NFTs have ventured into the realm of 3D designs, breathing life into sculptures, animations, and immersive experiences. NFT studios harness the power of cutting-edge software, including virtual reality and 3D modeling tools, to craft meticulously detailed and captivating pieces that bridge the gap between the physical and digital worlds."],
    subtitle2: "Empowering Artists and Redefining Ownership",
    description2: ["The traditional art world has long grappled with issues of authenticity, provenance, and the value of digital art. NFT studios for 3D designs offer a revolutionary solution by providing artists with a direct channel to showcase and monetize their creations. These studios empower artists to retain ownership of their work and receive a fair share of profits every time their NFT changes hands in the secondary market. This democratization of art not only benefits creators but also transforms the way art collectors perceive and invest in digital creations."],
    subtitle3: "A Canvas for Limitless Imagination",
    description3: ["One of the most exciting aspects of NFT studios specializing in 3D designs is the boundless creativity they foster. From intricate sculptures that defy gravity to virtual landscapes that transport viewers to fantastical realms, these studios empower artists to experiment with dimensions, perspectives, and narratives that were previously unattainable. Collectors, in turn, are treated to a gallery of masterpieces that challenge conventions and blur the lines between the real and virtual worlds."],
    subtitle4: "Catalyzing Digital Ownership and Authenticity",
    description4: ["One of the game-changing elements that NFT studios bring to the table is the inherent proof of ownership and authenticity. Each NFT is verifiably unique and tamper-proof, as the blockchain ledger maintains a record of transactions. This eradicates concerns about counterfeit works, fraud, and duplication that have plagued the art market for centuries. Collectors can confidently invest in NFTs knowing they are acquiring an original, limited edition creation."],
    subtitle5: "Shaping the Future of Art and Beyond",
    description5: ["The rise of NFT studios for 3D designs signifies a seismic shift in how we produce, consume, and value art. It not only opens doors for established artists to explore new mediums but also nurtures emerging talents to experiment and flourish. Moreover, the fusion of art and technology in this manner paves the way for collaborations that transcend borders and disciplines, as artists, developers, and enthusiasts from around the world unite to craft immersive experiences that defy categorization. ","\n","\n In conclusion, NFT studios specializing in 3D designs are shaping the trajectory of the art industry in unprecedented ways. Their fusion of technology and creativity, empowerment of artists, and redefinition of ownership are revolutionizing how we perceive, interact with, and invest in art. As these studios continue to push boundaries and innovate, they lay the foundation for a future where art knows no bounds, bridging the gap between the tangible and the digital, and inviting us to explore realms of imagination yet to be uncovered."],
    image: img3,
  },
  {
    title: "Unleashing Creativity: 3D Character Design",
    subtitle:"In the dynamic world of animation, gaming, and virtual experiences, 3D character modeling and design play a pivotal role in bringing imaginative worlds to life. As technology continues to advance, the demand for visually captivating and intricately designed characters grows exponentially. This article explores the artistry and craftsmanship behind 3D character modeling and design and its vital significance in the creative industries.",
    description: "In the dynamic world of animation, gaming, and virtual experiences, 3D character modeling and design play a pivotal role in bringing imaginative worlds to life.",
    subtitle1: "Understanding 3D Character Modeling and Design",
    description1: ["3D character modeling is the process of creating digital representations of characters with depth and dimensionality. Unlike traditional 2D illustrations, 3D character modeling allows artists to develop characters that can be viewed from any angle, adding realism and depth to their appearance. The process involves manipulating digital vertices, edges, and faces to form a three-dimensional mesh that becomes the foundation of the character. ","\n","\n The design aspect of 3D character modeling is equally crucial. It encompasses conceptualizing the character's appearance, personality, and role within the narrative. Designers meticulously consider the character's features, clothing, accessories, and overall aesthetics to ensure they align seamlessly with the intended narrative and evoke the desired emotions from the audience."],
    subtitle2: "The Creative Process",
    description2: ["The journey of creating a compelling 3D character is a collaborative and iterative process. It typically involves the following stages: ","\n","\n *Concept Art:* The initial phase involves conceptualizing the character's appearance through sketches and digital concept art. This stage lays the foundation for the character's design and provides a roadmap for the modeling process. ","\n * Modeling: * Using specialized software, artists sculpt the character in a 3D environment, carefully refining the mesh to achieve the desired form and proportions. Attention to detail is critical, as small adjustments can significantly impact the character's overall look. ","\n * Texturing and Materials: * Once the 3D model is complete, artists apply textures and materials to the character's surface, adding color, patterns, and realistic details. This step brings the character to life visually, enhancing its believability. ","\n * Rigging: * Rigging is the process of adding a digital skeleton to the character, allowing for realistic movement and animation. This step is vital for creating lifelike and expressive characters. ","\n * Animation: * With the character rigged, animators breathe life into it through movement and expressions, infusing personality and emotion into the character."],
    subtitle3: "The Impact of 3D Character Modeling and Design",
    description3: ["The impact of 3D character modeling and design extends far beyond entertainment and gaming. In industries like advertising, education, and architectural visualization, these visually compelling characters play a crucial role in engaging audiences, conveying messages, and enhancing immersive experiences. ","\n","\n In the ever-evolving landscape of animation and virtual worlds, 3D character modeling and design stand as a testament to human creativity and technological advancement. The meticulous artistry and craftsmanship that go into crafting these characters ensure that stories come alive, emotions are evoked, and audiences are transported to extraordinary realms. With the continuous advancement of technology, we can only imagine the breathtaking characters that lie ahead, enriching our lives and expanding the boundaries of our imagination."],
    image: img4,
  },
];
function Card() {
  const authCtx = React.useContext(AuthContext);
  const navigate = useNavigate();

  return (
  <>
      {array.map((card)=>{
        return(
        <div className={classes.card} 
            onClick={()=>{
              authCtx.setselectedTrend(card);
              navigate('/blog');
            }}>
      <div className={classes.image}>
            <img src={card.image} alt={`${card.title} Image`} />
        </div>
      <div className={classes.card_content}>
        <h3>{card.title}</h3>
        <div className={classes.descriptionContainer}>
        <p>{card.description}</p>
        </div>
      </div>
    </div>
        )
      })}
      </>
  );
}

export default Card;
