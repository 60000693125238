import React, { useEffect, useState } from 'react'
import classes from './Navbar.module.css'
import Logo from '../../../assets/topvd.webm'
import Logo1 from '../../../assets/LOGO0120-1.mov'
import gif from '../../../assets/test2.gif'
import { Link, useLocation } from 'react-router-dom'
import AuthContext from "../../../Admin Panel/auth-context";
import img2 from "../../../assets/Web3.png";
import sidebarmenu from '../../../assets/sidebar.png'

const array = {
  title: "Web 3.0 Unlocking the future",
  subtitle:"Web 3.0 marks a groundbreaking leap in the evolution of the internet, promising a decentralized future that transforms the way we engage with digital technology. This paradigm shift is driven by blockchain technology, introducing new levels of transparency, security, and user autonomy.",
  description: "Web 3.0 marks a groundbreaking leap in the evolution of the internet, promising a decentralized future that transforms the way we engage with digital technology.",
  subtitle1: "Understanding Web 3.0",
  description1: ["Web 3.0, also known as the decentralized web, diverges from its predecessors, Web 1.0 and Web 2.0, with its focus on decentralization and peer-to-peer interactions. At its core lies blockchain technology, enabling the creation of decentralized networks where data and applications are distributed across a network of nodes, eliminating the need for a central authority."],
  subtitle2: "The Potential of Web 3.0",
  description2: ["* Decentralization: * Web 3.0 shifts away from the centralized control of data and power, providing increased security and resilience against single points of failure. ","\n *Transparency and Trust:* Through blockchain's immutability, Web 3.0 ensures transparent and auditable records, fostering trust between users and reducing the risk of data manipulation. ","\n *User Control:* Users regain control of their data and digital identities, deciding how and where their information is utilized, promoting privacy and data ownership. ","\n *Tokenization and Digital Assets:*  Web 3.0 introduces the concept of digital assets and tokenization, enabling new revenue models, efficient transactions, and innovative business models. ","\n *Smart Contracts:* Web 3.0 incorporates smart contracts, self-executing agreements that automate processes and eliminate the need for intermediaries, streamlining transactions and reducing costs."],
  subtitle3: "The Future of Web 3.0",
  description3: ["The potential applications of Web 3.0 are vast and varied, impacting diverse industries such as finance, healthcare, supply chain management, gaming, and more. Decentralized applications (dApps) will revolutionize traditional processes, providing enhanced security, cost-effectiveness, and global accessibility. ","\n","\n Web 3.0 stands as a paradigm shift, promising a decentralized and user-centric digital landscape. With blockchain technology as its backbone, Web 3.0 introduces unparalleled transparency, trust, and security to the internet. As we venture further into this revolutionary era, businesses, individuals, and industries worldwide are poised to embrace a new era of digital autonomy and redefine the way we interact with technology and each other."],
  image: img2,
};


const Navbar = ({toggle}) => {
  // const navigate = useNavigate();
  const authCtx = React.useContext(AuthContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (path !== '/blog'){
    const handleScroll = () => {
      const isTop = window.scrollY > 20;
      if (isTop !== isScrolled) {
        setIsScrolled(isTop);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };} else {setIsScrolled(true)}
  }, [isScrolled, path]);
  return (
    <>
    <div className={`${isScrolled? classes.mobileview : classes.mobileview_before}`}>
        <div></div>
        <div className={classes.mobilelogo_con}>
        <Link to="/"><img src={gif} alt="My GIF Image" style={{ width:'45%'}}/></Link>
        </div>
        <div className={classes.mobilemenu} onClick={toggle}>
          <img src={sidebarmenu} alt='' style={{position:'absolute', right: '30px', top:'30px'}}/>
        </div>
    </div>
    <div className={`${
      isScrolled ? classes.navbar_container : classes.navbar_container_before
    }`}>
        <div className={classes.logo_conainer}>
          <Link to="/">
      {/* <video autoPlay loop muted style={{objectFit: 'contain', width:'25%'}}>
      <source src={Logo} type="video/webm" />
      <source src={Logo1} type="video/mp4" />
      </video> */}
      <img src={gif} alt="My GIF Image" style={{objectFit: 'contain', width:'25%'}}/>
      </Link>
        </div>
        <div className={classes.routing_container}>
          <Link to="/" style={{textDecoration: 'none', color:'white'}}><p onClick={() => {
                                                               const targetDiv = document.getElementById('targetDiv');
                                                               if (targetDiv) {
                                                                 targetDiv.scrollIntoView({ behavior: 'smooth' });
                                                               }}}>services</p></Link>
           <Link to="/blog" style={{textDecoration: 'none', color:'white'}}><p onClick={()=>{authCtx.setselectedTrend(array)}}>news</p></Link> 
           <Link to="/about" style={{textDecoration: 'none', color:'white'}}><p>about</p></Link>
           <Link to="/contactus"><button >request a quote</button></Link> 
        </div>
    </div>
    </>

  )
}

export default Navbar