import React from 'react'
import classes from "./OurMission.module.css";
import img7 from "../../../assets/AboutImage7.png"
import img8 from "../../../assets/AboutImage8.png"
import aboutabs from "../../../assets/aboutabs.png"

const OurMission = () => {
  return (
    <div className={classes.OurMissionContainer}>
    <img src={aboutabs} alt='' className={classes.bubbleImg}/>
        <div className={classes.imageContainer}>
            <img src={img7} alt='OurMission' className={classes.image7}/>
        </div>
        <div className={classes.contantContainer}>
          <div className={classes.header}>
            <h2>OUR MISSION</h2>
          </div>
          <div className={classes.contant}>
            <p>
              Our mission is to be the catalyst for the next generation
              of digital pioneers. We aim to equip business owners, brand
              ambassadors, and culture architects with the tools and
              information they need to thrive in the metaverse. Through
              our diverse expertise and a vast network of industry
              professionals.
            </p>
          </div>
          <div className={classes.imageMobile}>
          <img src={img8} alt='CreativeCommunity' className={classes.image8}/>
        </div>
        </div>
    </div>
  )
}

export default OurMission
