import React from 'react'
import classes  from './ContactUsSection.module.css'
import { Link, useNavigate } from 'react-router-dom'
import aboutabs from "../../../assets/aboutabs.png"

const ContactUsSection = () => {
  return (
    <div className={classes.contact_container}>
      <div className={classes.title_container}>
          <h1>IMAGINE WHAT’S NEXT !</h1>
          <p>
          BUILD YOUR OWN METAVERSE
          </p>
        </div>
        <div className={classes.button}>

        <Link to="/contactus"><button>CONTACT OUR EXPERTS</button></Link>
              </div>
              <img src={aboutabs} alt='' className={classes.bubbleImg}/>
    </div>
  )
}

export default ContactUsSection
