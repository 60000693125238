import React from "react";
import classes from "./AboutIntro.module.css";
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import CreativeCommunity from "./CreativeCommunity";
import OurPurpose from "./OurPurpose";
import OurMission from "./OurMission";
import OurVision from "./OurVision";

const AboutIntro = () => {
  return (
    <div className={classes.blogintro_container}>
      <div className={classes.slideContainer}>
        <Controller>
            <Scene
              triggerHook="onLeave"
              duration={5000}
              offset={0}
              pin
            >
              {(progress) => (
                <div className={classes.sticky}>
                  <Timeline totalProgress={progress} paused>
                    <Timeline>
                        <Tween
                        from={{ x: '0%' }}
                        to={{ x: '-75.2%' }}
                      >
                        <div className={classes.contantContainer}>
                          <CreativeCommunity/>
                          <OurPurpose/>
                          <OurMission/>
                          <OurVision/>
                        </div>
                        </Tween>
                    </Timeline>
                  </Timeline>
                </div>
              )}
            </Scene>
        </Controller>
        </div>
        <div className={classes.mobileView}>
          <CreativeCommunity/>
          <OurPurpose/>
          <OurMission/>
          <OurVision/>
        </div>
    </div>
  );
};

export default AboutIntro;

