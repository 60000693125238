import React from 'react'
import classes from "./OurPurpose.module.css";
import img3 from "../../../assets/AboutImage3.png"
import img4 from "../../../assets/AboutImage4.png"
import img5 from "../../../assets/AboutImage5.png"
import img6 from "../../../assets/AboutImage6.png"
import img7 from "../../../assets/AboutImage7.png"
import aboutabs from "../../../assets/aboutabs.png"

const OurPurpose = () => {
  return (
    <div className={classes.OurPurposeContainer}>
    <img src={aboutabs} alt='' className={classes.bubbleImg}/>
        <div className={classes.imageContainer}>
          <div className={classes.rowImages}>
            <img src={img3} alt='OurPurpose' className={classes.image3}/>
            <img src={img4} alt='OurPurpose' className={classes.image4}/>
          </div>
          <div className={classes.image5Container}>
            <img src={img5} alt='OurPurpose' className={classes.image5}/>
          </div>
        </div>
        <div className={classes.contantContainer}>
          <div className={classes.header}>
            <h2>OUR PURPOSE</h2>
          </div>
          <div className={classes.contant}>
            <p>
              Metariba's purpose is to foster
              the growth and success of
              individuals and businesses
              within the web3.0 landscape.
              We are driven by a deep
              commitment to empower our
              community members with
              the necessary knowledge
              and resources to navigate the
              virtual realm with confidence.
            </p>
          </div>
        </div>
          <div className={classes.image6Container}>
            <img src={img6} alt='OurPurpose' className={classes.image6}/>
          </div>
          <div className={classes.imageMobile}>
          <img src={img7} alt='CreativeCommunity' className={classes.image7}/>
        </div>
    </div>
  )
}

export default OurPurpose
