import React from 'react'
import classes from "./OurVision.module.css";
import img8 from "../../../assets/AboutImage8.png"
import img9 from "../../../assets/AboutImage9.png"
import img2 from "../../../assets/AboutImage2.png"
import aboutabs from "../../../assets/aboutabs.png"

const OurVision = () => {
  return (
    <div className={classes.OurVisionContainer}>
    <img src={aboutabs} alt='' className={classes.bubbleImg} />
        <div className={classes.imageContainer}>
            <img src={img8} alt='OurVirsion' className={classes.image8}/>
          <img src={img2} alt='CreativeCommunity' className={classes.image2}/>
        </div>
        <div className={classes.contantContainer}>
          <div className={classes.header}>
            <h2>OUR VISION</h2>
          </div>
          <div className={classes.contant}>
            <p>
              Our vision is to empower our
              community members to lead
              the way in leveraging the
              potential of the virtual realm,
              driving meaningful change and
              creating a better future for all.we are dedicated to offering unparalleled
              guidance, enabling our community members to
              navigate the ever-changing landscape of web3.0 and seize
              new opportunities for growth and success.
            </p>
          </div>
        </div>
        <div className={classes.image9Container}>
            <img src={img9} alt='OurVirsion' className={classes.image9}/>
          </div>
          <div className={classes.imageMobile}>
          <img src={img9} alt='CreativeCommunity' className={classes.image7}/>
        </div>
    </div>
  )
}

export default OurVision
