import React from 'react'
import AboutIntro from './Intro Section/AboutIntro'
import Team from './Team Section/Team'
import Trends from '../Blog Page/Digital Trends Section/Trends'
import ContactUsSection from './ContactUs Section/ContactUsSection'

const AboutPage = () => {
  return (
    <div style={{background: ' linear-gradient(93deg, #121D85 12.46%, #000000 100%)'}}>
      <AboutIntro/>
      <Team/>
      <Trends/>
      <ContactUsSection/>
    </div>
  )
}

export default AboutPage
