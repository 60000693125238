import React from 'react'
import classes from './Clients.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import { Autoplay, EffectFade } from 'swiper/modules'
import Movearound from './Movearound'

const Clients = () => {
  return (
    <div className={classes.clients_container}>
        <div className={classes.clients_head}>
            <h5>OUR CLIENTS</h5>
            <h1>A Trusted Branding Agency & Design Partner</h1>
            <h5>We collaborate with brands of all sizes and across industries</h5>
        </div>
        <div className={classes.swiper}>
      <Movearound/>
        </div>
        <div className={classes.bottom}>
            <h1>Looking To Build A Memorable Brand?</h1>
            <button style={{width:'fit-content'}}>SPEAK WITH OUR EXPERTS</button>
        </div>
    </div>
  )
}

export default Clients