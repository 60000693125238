import React from "react";
import classes from "./Home.module.css";
import VD1 from "../../../assets/HomeVideo.mp4";
import scroll from '../../../assets/scroll.png'
// import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate()

  return (
    <div className={classes.home_container}>
      <div className={classes.videoContainer}>
      <video autoPlay loop muted playsInline className={classes.video1}>
        <source src={VD1} type="video/mp4" />
      </video>
      </div>
    <div className={classes.bg}>
      <div className={classes.home_content}>
        <div className={classes.head}>
          <h1>IMAGINE WHAT’S NEXT!</h1>
          <h4>BUILD YOUR OWN METAVERSE</h4>
          <button onClick={()=>navigate('/contactus')}>REQUEST A PROPOSAL {'>>'}</button>
            <img src={scroll} alt="" />
        </div>
        <div className={classes.bottom}>
          <h1>CHECK US IN ACTION!</h1>
          <h6>
            Much like the metaverse itself, Metariba is a community of creators
            helping clients approach web3.0 with clarity and confidence. Our
            purpose is simple – to empower the next generation of business
            owners, brand ambassadors and culture architects with the
            information they need to turn their unique innovations into value in
            the virtual world.
          </h6>
              <button onClick={()=>navigate('/contactus')}>Discover More</button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Home;
